<script setup>
import { ref, onMounted, computed } from 'vue'
import { axiosClient } from '../services/axiosService'
import { store } from '../store/store'

import ClassicLogin from './classiclogin.vue';
import CommonFooter from './commonfooter.vue';
import CommonHeader from './commonheader.vue';
import ExternalLogin from './externallogin.vue';
import Register from './register.vue';

//Primevue
import Message from 'primevue/message';
import Toast from 'primevue/toast';

const props = defineProps({
    authServerUrl: String,
    baseUrl: String,
    brandFolderName: String,
    companyLogo: String,
    companyName: String,
    displayRegister: Boolean,
    errorToDisplay: String,
    externalLoginProviders: Array,
    loginTitle: String,
    loginInstruction: String,
    loginAdditional: String,
    mobileApp: Boolean,
    returnUrl: String,
    systemDisabled: Boolean,
    systemName: String,
    systemUrl: String,
    username: String
})

// Data
const displayRegisterBlock = props.displayRegister && !props.systemDisabled && !props.mobileApp
const modelState = ref([])
const isError = ref(false)

// Methods
const login = (async (form) => {
    isError.value = false;
    let formData = new FormData();
    formData.append('Username', form.username);
    formData.append('Password', form.password);
    formData.append('RememberMe', form.rememberMe);
    formData.append('ReturnUrl', props.returnUrl);
    const http = axiosClient()
    await http.post(`${props.authServerUrl}Account/Login`, formData,
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then(response => {
            if (response.data['requires2Fa']) {
                window.location.replace(`${props.authServerUrl}Account/TwoFactorAuthentication?LoginMethod=Classic&ReturnUrl=${encodeURIComponent(response.data['returnUrl'])}&RememberMe=${response.data['rememberMe']}`);
            } else {
                window.location.replace(response.data['returnUrl'])
            }
        }).catch(error => {
            isError.value = true;
            if (error.response === undefined) {
                if (error.message.toLowerCase().includes('network')) {
                    this.modelState.value.push('Network error - please check your internet connection');
                }
                else {
                    this.modelState.value.push(error.message);
                }
            } else if (error.response.status == 400) {
                const modelStateErrors = [];
                for (let e of error.response.data) {
                    e.Value.forEach((item, index) => {
                        modelStateErrors.push(item);
                    });
                }
                modelState.value = modelStateErrors;
            } else {
                let requestId = error.response.data.requestId;
                if (requestId == null) {
                    requestId = "unavailable";
                }
                window.location.replace(`${props.authServerUrl}Error/AjaxError?requestId=${requestId}`)
            }
        });
})

const performExternalLogin = ((providerId, caption) => {
    const returnAddressEncoded = encodeURIComponent(props.returnUrl);
    let externalLoginUrl = `${props.authServerUrl}Account/ExternalLogin?providerId=${providerId}&caption=${caption}&returnUrl=${returnAddressEncoded}`;
    window.location.replace(externalLoginUrl);
})

// Computed
const instruction = computed(() => {
    return !props.loginInstruction ?
        `You must have authorisation from ${props.companyName} to use this system. Please enter your user name and password below and click Log In to continue.` :
        props.loginInstruction
})

const inactiveMessage = computed(() => {
    return `This system is no longer active. Please contact your ${props.systemName} administrator for further information or help.`
})

// Lifecycle hooks
onMounted(() => {
    if (props.errorToDisplay != null) {
        isError.value = true;
        modelState.value.push(props.errorToDisplay)
    }
    if (props.mobileApp) {
        store.setMobileApp(true)
    }
})

</script>

<template>
    <Toast class="hidden sm:block" position="bottom-right" />
    <Toast class="block sm:hidden" position="center-right" />
    <div class="backdrop flex flex-col items-end">
        <div class="h-screen side-panel px-2 bg-background animated relative overflow-x-hidden overflow-y-auto">
            <div class="flex flex-col h-screen">
                <common-header :company-logo="companyLogo" :mobileApp="mobileApp"></common-header>
                <div class="main-content mx-4 grow">
                    <div class="my-4 block text-textOnBackground text-wrap">
                        <div v-html="loginTitle" class="text-lg sm:text-xl"></div>
                        <div v-html="instruction" class="text-sm sm:text-base"></div>
                    </div>
                    <Message v-if="systemDisabled" v-html="inactiveMessage" severity="warn" class="mb-4" :closable="false">
                    </Message>
                    <Message v-if="isError" severity="error" class="mb-4">
                        <ul>
                            <li v-for="error in modelState">
                                <div v-html="error"></div>
                            </li>
                        </ul>
                    </Message>
                    <external-login @external-login="performExternalLogin"
                                    v-if="externalLoginProviders.length > 0 && !systemDisabled"
                                    :external-login-providers="externalLoginProviders">
                    </external-login>
                    <classic-login @login="login" :username="username" :auth-server-url="authServerUrl"
                                   :return-url="returnUrl">
                    </classic-login>
                    <register v-if="displayRegisterBlock" :base-url="baseUrl" :system-name="systemName">
                    </register>
                </div>
                <common-footer :system-name="systemName" :system-url="systemUrl" :brand-folder-name="brandFolderName"></common-footer>
            </div>
        </div>
    </div>
</template>
