<script setup>
import { inject, computed, ref, onMounted } from 'vue'
import { store } from '../store/store'

// Primevue
import Button from 'primevue/button'
import Image from 'primevue/image'
import Toolbar from 'primevue/toolbar'

const props = defineProps({
  companyLogo: String,
  mobileApp: Boolean,
})

const $cookies = inject('$cookies')
const cookieTheme = ref('')

const showToggle = computed(() => {
  return cookieTheme !== 'mobileApp'
})

const toggleTheme = () => {
  const root = document.getElementsByTagName('html')[0]
  root.classList.toggle('dark')
  root.classList.toggle('light')
  const newTheme = root.classList.contains('dark') ? 'dark' : 'light'
  if ($cookies) {
    $cookies.set('theme', newTheme, -1, undefined, undefined, undefined, 'Strict')
  }
  cookieTheme.value = newTheme
  store.changeTheme(newTheme)
}

const iconClass = computed(() => {
  return cookieTheme.value === 'light' ? 'fa-moon-stars' : 'fa-brightness-low'
})

onMounted(() => {
  if (props.mobileApp) {
    if ($cookies) {
      $cookies.set('theme', 'mobileApp', -1, undefined, undefined, undefined, 'Strict')
    }
  }
  cookieTheme.value = $cookies?.get('theme') ?? 'light'
  const root = document.getElementsByTagName('html')[0]
  root.classList.add(cookieTheme.value)
  store.changeTheme(cookieTheme.value)
})

</script>
<template>
  <header>
    <Toolbar>
      <template #center>
        <Image :src="companyLogo" alt="Company Logo"
          :pt="{ image: { class: 'h-16 sm:h-20 border border-solid border-background rounded-xl' }, root: { class: 'flex justify-center' } }" />
      </template>
      <template #end>
        <Button v-if="showToggle" @click="toggleTheme" aria-label="Toggle between light and dark theme" severity="help"
          :icon="`fa-sharp fa-light ${iconClass}`"></Button>
      </template>
    </Toolbar>
  </header>
</template>