﻿import { reactive } from 'vue'

export const store = reactive({
    currentTheme: 'light',
    mobileApp: false,

    changeTheme(newTheme) {
        this.currentTheme = newTheme
    },
    setMobileApp(isMobileApp) {
        this.mobileApp = isMobileApp
    }
})