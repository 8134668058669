﻿/** 
 * PrimeVue configuration
 */

// MentorNet Design with Tailwind
const MentorNetDesign = {

    global: {
        css: `
        .progress-spinner-circle {
            stroke-dasharray: 89, 200;
            stroke-dashoffset: 0;
            stroke: hsl(var(--twc-primary));
            animation: p-progress-spinner-dash 1.5s ease-in-out infinite;
            stroke-linecap: round;
        }

        @keyframes p-progress-spinner-dash{
            0% {
                stroke-dasharray: 1, 200;
                stroke-dashoffset: 0;
            }
            
            50% {
                stroke-dasharray: 89, 200;
                stroke-dashoffset: -35px;
            }
            100% {
                stroke-dasharray: 89, 200;
                stroke-dashoffset: -124px;
            }
        }
    `
    },
    accordion: {
        root: 'mb-1 drop-shadow-lg rounded-lg',
        accordiontab: {
            root: 'mb-1 rounded-lg',
            header: ({ props }) => ({
                class: [
                    { 'select-none pointer-events-none cursor-default opacity-60': props?.disabled } // Condition
                ]
            }),
            headerAction: ({ context }) => ({
                class: [
                    'flex items-center cursor-pointer relative no-underline select-none', // Alignments
                    'p-5 transition duration-200 ease-in-out rounded-t-md transition-shadow duration-200', // Padding and transition
                    'bg-info text-textOnInfo focus:outline-none focus:ring-info focus:ring-offset-4 focus:ring-1', // Borders and colors
                    { 'rounded-br-md rounded-bl-md': !context.active, 'rounded-br-0 rounded-bl-0 text-gray-800': context.active } // Condition
                ]
            }),
            headerIcon: 'inline-block mr-2',
            headerTitle: 'leading-none',
            content: {
                class: [
                    'py-2 px-5 bg-background text-textOnBackground border-t-0 rounded-tl-none rounded-tr-none rounded-br-lg rounded-bl-lg'
                ]
            },
            transition: {
                enterFromClass: 'max-h-0',
                enterActiveClass: 'overflow-hidden transition-all duration-500 ease-in-out',
                enterToClass: 'max-h-40	',
                leaveFromClass: 'max-h-40',
                leaveActiveClass: 'overflow-hidden transition-all duration-500 ease-in',
                leaveToClass: 'max-h-0'
            }
        }
    },
    button: {
        root: ({ props, context }) => ({
            class: [
                'items-center cursor-pointer inline-flex overflow-hidden relative select-none text-center align-bottom max-h-10',
                'transition duration-200 ease-in-out',
                'focus:ring-2 focus:ring-offset-2 hover:ring-2 hover:ring-offset-2 outline-none',
                {
                    'text-textOnPrimary bg-primary border border-primary ring-primary ring-offset-secondary':
                        !props.link &&
                        props.severity === null &&
                        !props.text &&
                        !props.outlined &&
                        !props.plain,
                    'text-textOnBackground bg-transparent border-transparent ring-primary':
                        props.link
                },
                {
                    'text-textOnSecondary bg-secondary border border-secondary ring-secondary ring-offset-primary':
                        props.severity === 'secondary' &&
                        !props.text &&
                        !props.outlined &&
                        !props.plain,
                },
                {
                    'text-textOnBackground border border-textOnBackground bg-transparent text-sm px-2 py-1':
                        props.severity === 'help'
                },
                {
                    'shadow-lg': props.raised
                },
                {
                    'rounded-xl': !props.rounded,
                    'rounded-full': props.rounded
                },
                {
                    'bg-transparent border-transparent':
                        props.text &&
                        !props.plain,
                    'text-textOnPrimary ':
                        props.text &&
                        props.severity === 'secondary' &&
                        !props.plain,
                },
                {
                    'shadow-lg': props.raised && props.text
                },
                {
                    'text-textOnPrimary ':
                        props.plain &&
                        props.text,
                    'text-textPrimary border border-primary':
                        props.plain &&
                        props.outlined,
                    'text-textOnPrimary bg-secondary border border-secondary hover:bg-secondaryHover hover:border-secondaryHover':
                        props.plain &&
                        !props.outlined &&
                        !props.text
                },
                {
                    'bg-transparent border':
                        props.outlined &&
                        !props.plain,
                    'text-primary border border-primary hover:bg-primaryHover':
                        props.outlined &&
                        (props.severity === null || props.severity === 'info') &&
                        !props.plain,
                    'text-textOnPrimary border border-secondary hover:bg-secondaryHover':
                        props.outlined &&
                        props.severity === 'secondary' &&
                        !props.plain,
                },
                {
                    'px-4 py-3 text-base': props.size === null && props.severity !== 'help',
                    'text-xs py-2 px-3': props.size === 'small' && props.severity !== 'help',
                    'text-xl py-3 px-4': props.size === 'large' && props.severity !== 'help'
                },
                {
                    'flex-column': props.iconPos == 'top' || props.iconPos == 'bottom'
                },
                {
                    'opacity-60 pointer-events-none cursor-default': context.disabled
                }
            ]
        }),
        label: ({ props }) => ({
            class: [
                'flex-1',
                'duration-200',
                'font-medium',
                {
                    'hover:underline': props.link
                },
                { 'invisible w-0': props.label == null }
            ]
        }),
        icon: ({ props }) => ({
            class: [
                'mx-0',
                {
                    'mr-2': props.iconPos == 'left' && props.label != null,
                    'ml-2 order-1': props.iconPos == 'right' && props.label != null,
                    'mb-2': props.iconPos == 'top' && props.label != null,
                    'mt-2 order-2': props.iconPos == 'bottom' && props.label != null
                }
            ]
        }),
        badge: ({ props }) => ({
            class: [{ 'ml-2 w-4 h-4 leading-none flex items-center justify-center': props.badge }]
        })
    },
    calendar: {
        root: ({ props }) => ({
            class: [
                'inline-flex relative',
                {
                    'opacity-60 select-none pointer-events-none cursor-default': props.disabled,
                    'p-input-icon-right': props.iconDisplay === 'input'
                }
            ]
        }),
        buttonBar: {
            class: [
                'w-[96%] mx-auto flex justify-between items-center py-2 px-4 border-solid border-t-2 border-t-backgroundOffset3'
            ]
        },
        input: {
            class: [
                'w-full font-sans text-textOnSecondary bg-secondary p-3 border border-gray-300 transition-colors duration-200 appearance-none rounded-lg',
                'hover:ring-1 hover:ring-offset-2 hover:ring-primary focus:outline-none focus-visible:ring-1 focus-visible:ring-offset-2 focus-visible:ring-primary',
                'selection:bg-primary selection:text-textOnPrimary'
            ]
        },
        panel: ({ props }) => ({
            class: [
                'bg-background shadow-card',
                'min-w-[250px]',
                {
                    'border-0 absolute': !props.inline,
                    'inline-block overflow-x-auto p-2 rounded-lg': props.inline
                }
            ]
        }),
        header: {
            class: [
                'flex items-center justify-between',
                'p-2 bg-background font-semibold m-0 rounded-t-lg',
                'w-[96%] mx-auto flex justify-between items-center py-2 px-4 border-solid border-b-2 border-t-backgroundOffset3'
            ]
        },
        previousbutton: {
            class: [
                'flex items-center justify-center cursor-pointer overflow-hidden relative text-textOnBackground',
                'w-8 h-8 border-0 bg-transparent rounded-full transition-colors duration-200 ease-in-out',
                'hover:ring-1 hover:ring-offset-2 hover:ring-primary focus:outline-none focus-visible:ring-1 focus-visible:ring-offset-2 focus-visible:ring-primary'
            ]
        },
        title: 'leading-8 mx-auto',
        monthTitle: {
            class: ['text-textOnBackground transition duration-200 font-semibold p-2', 'mr-2', 'hover:text-primary']
        },
        yearTitle: {
            class: ['text-textOnBackground transition duration-200 font-semibold p-2', 'hover:text-primary']
        },
        nextbutton: {
            class: [
                'flex items-center justify-center cursor-pointer overflow-hidden relative text-textOnBackground',
                'w-8 h-8 border-0 bg-transparent rounded-full transition-colors duration-200 ease-in-out',
                'hover:ring-1 hover:ring-offset-2 hover:ring-primary focus:outline-none focus-visible:ring-1 focus-visible:ring-offset-2 focus-visible:ring-primary'
            ]
        },
        table: {
            class: ['border-collapse w-full', 'my-1']
        },
        tableheadercell: 'p-1',
        weekday: 'text-textOnBackground',
        day: 'p-1',
        daylabel: ({ context }) => ({
            class: [
                'w-10 h-10 rounded-full transition-shadow duration-200 border-transparent border',
                'flex items-center justify-center mx-auto overflow-hidden relative',
                'focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-primary',
                {
                    'opacity-60 cursor-default': context.disabled,
                    'cursor-pointer': !context.disabled
                },
                {
                    'text-textOnBackground bg-transprent hover:ring-1 hover:ring-offset-2 hover:ring-primary': !context.selected && !context.disabled,
                    'text-primary ring-1 ring-offset-2 ring-primary': context.selected && !context.disabled
                }
            ]
        }),
        monthpicker: 'my-1',
        month: ({ context }) => ({
            class: [
                'w-1/3 inline-flex items-center justify-center cursor-pointer overflow-hidden relative',
                'p-2 transition-shadow duration-200 rounded-lg',
                'focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-primary',
                {
                    'text-textOnBackground bg-transprent hover:ring-1 hover:ring-offset-2 hover:ring-primary': !context.selected && !context.disabled,
                    'text-primary ring-1 ring-offset-2 ring-primary': context.selected && !context.disabled
                }
            ]
        }),
        yearpicker: {
            class: ['my-1']
        },
        year: ({ context }) => ({
            class: [
                'w-1/2 inline-flex items-center justify-center cursor-pointer overflow-hidden relative',
                'p-2 transition-shadow duration-200 rounded-lg',
                'focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-primary',
                {
                    'text-textOnBackground bg-transprent hover:ring-1 hover:ring-offset-2 hover:ring-primary': !context.selected && !context.disabled,
                    'text-primary ring-1 ring-offset-2 ring-primary': context.selected && !context.disabled
                }
            ]
        }),
        timepicker: {
            class: ['flex justify-center items-center', 'border-t-1 border-solid border-gray-300 p-2']
        },
        separatorcontainer: 'flex items-center flex-col px-1',
        separator: 'text-xl',
        hourpicker: 'flex items-center flex-col px-2',
        minutepicker: 'flex items-center flex-col px-2',
        ampmpicker: 'flex items-center flex-col px-2',
        incrementbutton: {
            class: [
                'flex items-center justify-center cursor-pointer overflow-hidden relative',
                'w-8 h-8 text-textOnBackground border-0 bg-transparent rounded-full transition-colors duration-200 ease-in-out',
                'hover:ring-1 hover:ring-offset-2 hover:ring-primary hover:border-transparent'
            ]
        },
        decrementbutton: {
            class: [
                'flex items-center justify-center cursor-pointer overflow-hidden relative',
                'w-8 h-8 text-textOnBackground border-0 bg-transparent rounded-full transition-colors duration-200 ease-in-out',
                'hover:ring-1 hover:ring-offset-2 hover:ring-primary hover:border-transparent'
            ]
        },
        groupcontainer: 'flex',
        group: {
            class: ['flex-1', 'border-l border-gray-300 pr-0.5 pl-0.5 pt-0 pb-0', 'first:pl-0 first:border-l-0']
        },
        transition: {
            enterFromClass: 'opacity-0 scale-75',
            enterActiveClass: 'transition-transform transition-opacity duration-150 ease-in',
            leaveActiveClass: 'transition-opacity duration-150 ease-linear',
            leaveToClass: 'opacity-0'
        }
    },
    card: {
        root: {
            class: [
                'bg-background text-textOnBackground shadow-none sm:border sm:border-background sm:shadow-card rounded-md my-5' // Background, text color, box shadow, and border radius.
            ]
        },
        content: 'py-2', // Padding.
        header: 'font-medium text-base sm:text-lg mb-2', // Font size, font weight, and margin bottom.
        title: 'font-semibold text-lg sm:text-xl mb-2', // Font size, font weight, and margin bottom.
        subtitle: {
            class: [
                'font-normal text-sm sm:base mb-2' // Font weight, margin bottom, and text color.
            ]
        },
        footer: 'pt-5' // Top padding.
    },
    checkbox: {
        root: {
            class: ['cursor-pointer inline-flex relative select-none align-bottom', 'w-6 h-6']
        },
        input: ({ props, context }) => ({
            class: [
                'flex items-center justify-center',
                'border-2 border-textOnBackground w-6 h-6 rounded-lg transition-colors duration-200',
                {
                    'hover:ring-primary hover:ring-offset-2 hover:ring-2 focus-visible:outline-none focus-visible:ring-primary focus-visible:ring-offset-2 focus-visible:ring-2]': !props.disabled,
                    'cursor-default opacity-60': props.disabled,
                    'ring-1 ring-offset-2 ring-primary': context.focused,
                },
            ]
        }),
        icon: 'text=textOnBackground'
    },
    dialog: {
        root: ({ state }) => ({
            class: [
                'rounded-lg shadow-lg border-0',
                'max-h-[90%] transform scale-100',
                'm-0',
                {
                    'transition-none transform-none !w-screen !h-screen !max-h-full !top-0 !left-0': state.maximized
                }
            ]
        }),
        header: {
            class: ['flex items-center justify-between shrink-0', 'bg-primary text-textOnPrimary border-t-0 rounded-tl-lg rounded-tr-lg p-3']
        },
        headerTitle: {
            class: 'font-normal text-base'
        },
        headerIcons: {
            class: 'flex items-center'
        },
        closeButton: {
            class: [
                'flex items-center justify-center overflow-hidden relative',
                'w-8 h-8 text-textOnPrimary border-0 bg-transparent rounded-full transition duration-200 ease-in-out mr-2 last:mr-0',
                'hover:ring-1 hover:ring-offset-2 hover:ring-textOnPrimary focus:outline-none focus-visible:ring-1 focus-visible:ring-offset-2 focus-visible:ring-textOnPrimary'
            ]
        },
        closeButtonIcon: {
            class: 'w-4 h-4 inline-block'
        },
        content: ({ state, instance }) => ({
            class: [
                'overflow-y-auto',
                'bg-background text-textOnBackground text-sm px-6 pb-8 pt-0',
                {
                    grow: state.maximized
                },
                {
                    'rounded-bl-lg rounded-br-lg': !instance.$slots.footer
                }
            ]
        }),
        footer: {
            class: ['flex gap-2 shrink-0 justify-end align-center', 'border-t-0 bg-background text-textOnBackground px-6 pb-6 text-right rounded-b-lg']
        },
        mask: ({ props }) => ({
            class: ['transition duration-200', { 'bg-black/40': props.modal }]
        }),
        transition: ({ props }) => {
            return props.position === 'top'
                ? {
                    enterFromClass: 'opacity-0 scale-75 translate-x-0 -translate-y-full translate-z-0',
                    enterActiveClass: 'transition-all duration-200 ease-out',
                    leaveActiveClass: 'transition-all duration-200 ease-out',
                    leaveToClass: 'opacity-0 scale-75 translate-x-0 -translate-y-full translate-z-0'
                }
                : props.position === 'bottom'
                    ? {
                        enterFromClass: 'opacity-0 scale-75 translate-y-full',
                        enterActiveClass: 'transition-all duration-200 ease-out',
                        leaveActiveClass: 'transition-all duration-200 ease-out',
                        leaveToClass: 'opacity-0 scale-75 translate-x-0 translate-y-full translate-z-0'
                    }
                    : props.position === 'left' || props.position === 'topleft' || props.position === 'bottomleft'
                        ? {
                            enterFromClass: 'opacity-0 scale-75 -translate-x-full translate-y-0 translate-z-0',
                            enterActiveClass: 'transition-all duration-200 ease-out',
                            leaveActiveClass: 'transition-all duration-200 ease-out',
                            leaveToClass: 'opacity-0 scale-75  -translate-x-full translate-y-0 translate-z-0'
                        }
                        : props.position === 'right' || props.position === 'topright' || props.position === 'bottomright'
                            ? {
                                enterFromClass: 'opacity-0 scale-75 translate-x-full translate-y-0 translate-z-0',
                                enterActiveClass: 'transition-all duration-200 ease-out',
                                leaveActiveClass: 'transition-all duration-200 ease-out',
                                leaveToClass: 'opacity-0 scale-75 opacity-0 scale-75 translate-x-full translate-y-0 translate-z-0'
                            }
                            : {
                                enterFromClass: 'opacity-0 scale-75',
                                enterActiveClass: 'transition-all duration-200 ease-out',
                                leaveActiveClass: 'transition-all duration-200 ease-out',
                                leaveToClass: 'opacity-0 scale-75'
                            }
        }
    },
    divider: {
        root: ({ props }) => ({
            class: [
                'flex relative', // alignments.
                'border-t-2, border-textOnBackground',
                {
                    'w-full mx-0 py-0 px-5 before:block before:left-0 before:absolute before:top-1/2 before:w-full before:border-t before:border-textOnBackground': props.layout == 'horizontal', // Padding and borders for horizontal layout.
                    'min-h-full mx-4 md:mx-5 py-5 before:block before:min-h-full before:absolute before:left-1/2 before:top-0 before:transform before:-translate-x-1/2 before:border-l before:textOnBackground':
                        props.layout == 'vertical' // Padding and borders for vertical layout.
                },
                {
                    'before:border-solid': props.type == 'solid',
                    'before:border-dotted': props.type == 'dotted',
                    'before:border-dashed': props.type == 'dashed'
                } // Border type condition.
            ]
        }),
        content: 'px-1 bg-background z-10' // Padding and background color.
    },
    dropdown: {
        root: ({ props }) => ({
            class: [
                'cursor-pointer inline-flex relative select-none',
                'bg-secondary transition-colors duration-200 ease-in-out rounded-md',
                'w-full shadow-md text-textOnSecondary',
                'py-2 pl-4 pr-1.5 mt-2 mb-2',
                { 'opacity-60 select-none pointer-events-none cursor-default': props.disabled }
            ]
        }),
        input: ({ props }) => ({
            class: [
                'cursor-pointer block flex flex-auto overflow-hidden text-ellipsis relative text-textOnSecondary',
                'bg-transparent border-1',
                'transition duration-200 bg-transparent rounded appearance-none',
                'outline-none focus-visible:ring-1 focus-visible:ring-offset-2 focus-visible:ring-primary',
                'hover:ring-1 hover:ring-offset-2 hover:ring-primary',
                { 'pr-7': props.showClear }
            ]
        }),
        trigger: {
            class: ['flex items-center justify-center shrink-0', 'bg-transparent text-textOnSecondary w-12 rounded-tr-lg rounded-br-lg']
        },
        wrapper: {
            class: ['max-h-[200px] overflow-auto', 'bg-background text-textOnBackground border-2 border-background rounded-md shadow-card']
        },
        list: {
            class: 'py-3 list-none'
        },
        item: ({ context }) => ({
            class: [
                'cursor-pointer font-normal overflow-hidden relative',
                'px-4 py-2 sm:py-3 border-0 transition-shadow duration-200 rounded-none',
                'text-sm sm:text-base text-textOnBackground',
                {
                    'hover:bg-backgroundOffset1': !context.focused && !context.selected,
                    'bg-backgroundOffset2 hover:bg-backgroundOffset2': context.focused && !context.selected,
                    'bg-backgroundOffset3 hover:bg-backgroundOffset3': context.focused && context.selected,
                    'bg-backgroundOffset3': !context.focused && context.selected
                }
            ]
        }),
        itemgroup: {
            class: ['m-0 p-3 text-textOnBackground bg-background font-bold', 'cursor-auto']
        },
        header: {
            class: ['p-3 border-b border-gray-300 text-textOnBackground bg-gray-100 mt-0 rounded-tl-lg rounded-tr-lg']
        },
        filtercontainer: {
            class: 'relative'
        },
        filterinput: {
            class: [
                'pr-7 -mr-7',
                'w-full',
                'font-sans text-textOnBackground bg-background py-3 px-3 border border-gray-300 transition duration-200 rounded-lg appearance-none',
                'hover:border-background focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)]'
            ]
        },
        filtericon: {
            class: '-mt-2 absolute top-1/2'
        },
        clearicon: {
            class: 'text-textOnSecondary right-12 -mt-2 absolute top-1/2'
        },
        transition: {
            enterFromClass: '-translate-y-0 scale-y-0 opacity-0',
            enterActiveClass: 'transform transition duration-500 ease-custom',
            enterToClass: 'translate-y-0 scale-y-100 opacity-100',
            leaveFromClass: 'translate-y-0 scale-y-100 opacity-100',
            leaveActiveClass: 'transform transition duration-300 ease-custom',
            leaveToClass: '-translate-y-0 scale-y-0 opacity-0'
        }
    },
    fieldset: {
        root: {
            class: [
                'bg-transparent' // Borders, background, text color, spacing, and inline size.
            ]
        },
        legend: ({ props }) => ({
            class: [
                'border border-gray-300 text-textOnSecondary bg-secondary font-bold rounded-md',
                {
                    'p-0 transition-none hover:bg-gray-100 hover:border-gray-300 hover:text-gray-900 dark:hover:text-white/80 dark:hover:bg-gray-800/80 dark:focus:shadow-[inset_0_0_0_0.2rem_rgba(147,197,253,0.5)]': props.toggleable,
                    'p-5': !props.toggleable
                }
            ]
        }),
        toggler: ({ props }) => ({
            class: [
                'flex items-center justify-center',
                {
                    'p-5 text-gray-700 rounded-md transition-none cursor-pointer overflow-hidden relative select-none hover:text-gray-900 focus:focus:shadow-[inset_0_0_0_0.2rem_rgba(191,219,254,1)] ':
                        props.toggleable
                }
            ]
        }),
        togglerIcon: 'mr-2 inline-block', // Margin and display style.
        legendTitle: 'flex items-center justify-center leading-none', // alignments, and leading style.
        transition: {
            enterFromClass: 'max-h-0',
            enterActiveClass: 'overflow-hidden transition-all duration-500 ease-in-out',
            enterToClass: 'max-h-40	',
            leaveFromClass: 'max-h-40',
            leaveActiveClass: 'overflow-hidden transition-all duration-500 ease-in',
            leaveToClass: 'max-h-0'
        }
    },
    image: {
        root: 'relative inline-block',
        button: {
            class: [
                'absolute inset-0 flex items-center justify-center opacity-0 transition-opacity duration-300',
                'bg-transparent text-gray-100',
                'hover:opacity-100 hover:cursor-pointer hover:bg-black hover:bg-opacity-50' //Hover
            ]
        },
        mask: {
            class: ['fixed top-0 left-0 w-full h-full', 'flex items-center justify-center', 'bg-black bg-opacity-90']
        },
        toolbar: {
            class: ['absolute top-0 right-0 flex', 'p-4']
        },
        rotaterightbutton: {
            class: [
                'flex justify-center items-center',
                'text-textOnSecondary bg-transparent w-12 h-12 rounded-full transition duration-200 ease-in-out mr-2',
                'hover:text-textOnBackground hover:bg-background/10',
                'focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)]'
            ]
        },
        rotaterighticon: 'w-6 h-6',
        rotateleftbutton: {
            class: [
                'flex justify-center items-center',
                'text-textOnSecondary bg-transparent w-12 h-12 rounded-full transition duration-200 ease-in-out mr-2',
                'hover:text-textOnSecondary hover:bg-background/10',
                'focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)]'
            ]
        },
        rotatelefticon: 'w-6 h-6',
        zoomoutbutton: {
            class: [
                'flex justify-center items-center',
                'text-textOnSecondary bg-transparent w-12 h-12 rounded-full transition duration-200 ease-in-out mr-2',
                'hover:text-textOnSecondary hover:bg-background/10',
                'focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)]'
            ]
        },
        zoomouticon: 'w-6 h-6',
        zoominbutton: {
            class: [
                'flex justify-center items-center',
                'text-textOnSecondary bg-transparent w-12 h-12 rounded-full transition duration-200 ease-in-out mr-2',
                'hover:text-textOnSecondary hover:bg-background/10',
                'focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)]'
            ]
        },
        zoominicon: 'w-6 h-6',
        closebutton: {
            class: [
                'flex justify-center items-center',
                'text-textOnSecondary bg-transparent w-12 h-12 rounded-full transition duration-200 ease-in-out mr-2',
                'hover:text-textOnSecondary hover:bg-background/10',
                'focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)]'
            ]
        },
        closeicon: 'w-6 h-6',
        transition: {
            enterFromClass: 'opacity-0 scale-75',
            enterActiveClass: 'transition-all duration-150 ease-in-out',
            leaveActiveClass: 'transition-all duration-150 ease-in',
            leaveToClass: 'opacity-0 scale-75'
        }
    },
    inputtext: {
        root: ({ props, context }) => ({
            class: [
                'm-0 w-full',
                'font-sans text-textOnSecondary bg-secondary transition-colors duration-200 appearance-none rounded-lg shadow-md',
                'selection:bg-primary selection:text-textOnPrimary',
                {
                    'hover:ring-1 hover:ring-offset-2 hover:ring-primary focus:outline-none focus-visible:ring-1 focus-visible:ring-offset-2 focus-visible:ring-primary': !context.disabled,
                    'opacity-60 select-none pointer-events-none cursor-default': context.disabled
                },
                {
                    'text-lg px-4 py-4': props.size == 'large',
                    'text-xs px-2 py-2': props.size == 'small',
                    'py-2 px-3 text-base': props.size == null
                }
            ]
        })
    },
    message: {
        root: ({ props }) => ({
            class: [
                'my-2 py-2 rounded-lg drop-shadow-lg',
                {
                    'bg-info border-solid border-0 border-l-4 border-info text-textOnInfo': props.severity == 'info',
                    'bg-success border-solid border-0 border-l-4 border-success text-textOnSuccess': props.severity == 'success',
                    'bg-warning border-solid border-0 border-l-4 border-warning text-textOnWarning': props.severity == 'warn',
                    'bg-error border-solid border-0 border-l-4 border-error text-textOnError': props.severity == 'error'
                }
            ]
        }),
        wrapper: 'py-2 px-3 flex basis-full',
        icon: {
            class: ['w-6 h-6', 'text-xl mr-2']
        },
        text: 'font-sans text-xs sm:text-sm w-full',
        button: {
            class: ['w-8 h-8 rounded-full bg-transparent transition duration-200 ease-in-out', 'ml-auto overflow-hidden relative', 'flex items-center justify-center', 'hover:bg-white/30']
        },
        transition: {
            enterFromClass: 'opacity-0',
            enterActiveClass: 'transition-opacity duration-300',
            leaveFromClass: 'max-h-40',
            leaveActiveClass: 'overflow-hidden transition-all duration-300 ease-in',
            leaveToClass: 'max-h-0 opacity-0 !m-0'
        }
    },
    password: {
        root: ({ props }) => ({
            class: [
                'inline-flex relative w-full',
                {
                    'opacity-60 select-none pointer-events-none cursor-default': props.disabled
                }
            ]
        }),
        showicon: {
            class: ['absolute top-1/2 -mt-2', 'right-3 text-textOnSecondary']
        },
        hideicon: {
            class: ['absolute top-1/2 -mt-2', 'right-3 text-textOnSecondary']
        },
        transition: {
            enterFromClass: 'opacity-0 scale-75',
            enterActiveClass: 'transition-transform transition-opacity duration-150 ease-in',
            leaveActiveClass: 'transition-opacity duration-150 ease-linear',
            leaveToClass: 'opacity-0'
        }
    },
    progressspinner: {
        root: {
            class: ['relative mx-auto w-12 h-12 inline-block', 'before:block before:pt-full']
        },
        spinner: 'absolute top-0 bottom-0 left-0 right-0 m-auto w-full h-full transform origin-center animate-spin',
        circle: 'text-red-500 progress-spinner-circle'
    },
    radiobutton: {
        root: {
            class: ['relative inline-flex cursor-pointer select-none align-bottom', 'w-6 h-6']
        },
        input: ({ props, state}) => ({
            class: [
                'flex justify-center items-center',
                'w-6 h-6 text-textOnSecondary text-2xl rounded-full transition duration-200 ease-in-out',
                {
                    'fa-thin fa-circle': props.value !== props.modelValue,
                    'fa-thin fa-circle-check': props.value == props.modelValue
                },
                {
                    'hover:ring-1 hover:ring-offset-2 hover:ring-primary': !props.disabled,
                    'cursor-default opacity-60': props.disabled,
                    'ring-1 ring-offset-2 ring-primary': state.focused
                }
            ]
        })
    },
    textarea: {
        root: ({ context }) => ({
            class: [
                'm-0 w-full h-24', 'selection:bg-primary selection:text-textOnPrimary',
                'font-sans text-textOnSecondary bg-secondary transition-colors duration-200 appearance-none rounded-lg shadow-md',
                {
                    'hover:ring-1 hover:ring-offset-2 hover:ring-primary focus:outline-none focus-visible:ring-1 focus-visible:ring-offset-2 focus-visible:ring-primary': !context.disabled,
                    'opacity-60 select-none pointer-events-none cursor-default': context.disabled
                }
            ]
        })
    },
    toast: {
        root: ({ props }) => ({
            class: ['w-fit sm:w-96',
                {
                    'p-toast-center': props.position === 'center',
                    'p-toast-top-left': props.position === 'top-left',
                    'p-toast-top-center': props.position === 'top-center',
                    'p-toast-top-right': props.position === 'top-right',
                    'p-toast-bottom-left': props.position === 'bottom-left',
                    'p-toast-bottom-center': props.position === 'bottom-center',
                    'p-toast-bottom-right': props.position === 'bottom-right'
                }
            ]
        }),
        container: ({ props }) => ({
            class: [
                'my-2 rounded-md w-full',
                {
                    'bg-info border-solid border-0 border-l-4 border-info text-textOnInfo': props.message?.severity == 'info',
                    'bg-success border-solid border-0 border-l-4 border-success text-textOnSuccess': props.message?.severity == 'success',
                    'bg-warning border-solid border-0 border-l-4 border-warning text-textOnWarning': props.message?.severity == 'warn',
                    'bg-error border-solid border-0 border-l-4 border-error text-textOnError': props.message?.severity == 'error'
                }
            ]
        }),
        content: 'flex items-center py-2 px-3',
        icon: {
            class: ['w-6 h-6', 'text-lg mr-2']
        },
        text: 'text-sm font-normal flex flex-col flex-1 grow shrink ml-4',
        summary: 'font-semibold block',
        detail: 'mt-1 block',
        closebutton: {
            class: ['w-8 h-8 rounded-full bg-transparent transition duration-200 ease-in-out', 'ml-auto overflow-hidden relative', 'flex items-center justify-center', 'hover:bg-white/30']
        },
        transition: {
            enterFromClass: 'opacity-0 translate-x-0 translate-y-2/4 translate-z-0',
            enterActiveClass: 'transition-transform transition-opacity duration-300',
            leaveFromClass: 'max-h-40',
            leaveActiveClass: 'transition-all duration-500 ease-in',
            leaveToClass: 'max-h-0 opacity-0 mb-0 overflow-hidden'
        }
    },
    togglebutton: {
        root: ({ props, context }) => ({
            class: [
                'inline-flex cursor-pointer select-none items-center align-bottom text-center overflow-hidden relative',
                'px-2 py-1 m-2 rounded-md text-sm shadow-card',
                'border transition duration-200 ease-in-out',
                'hover:ring-2 hover:ring-offset-2 outline-none min-w-[40px]',
                {
                    'outline-none outline-offset-0 shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] ring-1 ring-offset-2 ring-primary': context.focused
                },
                {
                    'bg-background border-background hover:bg-backgroundOffset1 hover:border-backgroundOffset1':
                        !props.modelValue,
                    'bg-primary border-primary text-textOnPrimary hover:border-primary': props.modelValue
                },
                { 'opacity-60 select-none pointer-events-none cursor-default': props.disabled }
            ]
        }),
        label: ({ props }) => ({
            class: [
                'text-center w-full font-medium',
                {
                    'text-textOnBackground': !props.modelValue,
                    'text-textOnPrimary': props.modelValue
                }
            ]
        }),
        icon: ({ props }) => ({
            class: [
                ' mr-2',
                {
                    'text-textOnBackground': !props.modelValue,
                    'text-white': props.modelValue
                }
            ]
        })
    },
    toolbar: {
        root: {
            class: ['flex items-center justify-between flex-wrap', 'bg-background px-4']
        },
        start: 'flex items-start',
        center: 'flex items-center',
        end: 'flex items-center'
    },
    directives: {
        tooltip: {
            root: ({ context }) => ({
                class: [
                    'absolute shadow-md max-w-[500px] mx-2',
                    {
                        'py-0 px-1': context?.right || context?.left || (!context?.right && !context?.left && !context?.top && !context?.bottom),
                        'py-1 px-0': context?.top || context?.bottom
                    }
                ]
            }),
            arrow: ({ context }) => ({
                class: [
                    'absolute w-0 h-0 border-transparent border-solid',
                    {
                        '-m-t-1 border-y-[0.25rem] border-r-[0.25rem] border-l-0 border-r-gray-600': context?.right || (!context?.right && !context?.left && !context?.top && !context?.bottom),
                        '-m-t-1 border-y-[0.25rem] border-l-[0.25rem] border-r-0 border-l-gray-600': context?.left,
                        '-m-l-1 border-x-[0.25rem] border-t-[0.25rem] border-b-0 border-t-gray-600': context?.top,
                        '-m-l-1 border-x-[0.25rem] border-b-[0.25rem] border-t-0 border-b-gray-600': context?.bottom
                    }
                ]
            }),
            text: {
                class: 'p-3 bg-gray-600 text-white text-xs rounded-md whitespace-pre-line break-words'
            }
        }
    }
}

export const primeVueOptions = {
    csp: {
        nonce: 'k7tw3fyODk0qbjmYmd'
    },
    inputStyle: 'filled',
    unstyled: true,
    pt: MentorNetDesign
}