<script setup>

//Primevue
import Button from 'primevue/button'
import Divider from 'primevue/divider'

const props = defineProps({
    baseUrl: String,
    systemName: String
})

const registerLink = `${props.baseUrl}spa`

const routeToRegistration = () => {
    window.location.assign(registerLink);
}

</script>

<template>
    <div class="mt-4">
        <Divider class="mb-4" />
        <div class="flex flex-col sm:flex-row sm:justify-between">
            <div class="text-textOnBackground">
                Don't currently have a {{ systemName }} Account? Click Register.
            </div>
            <div class="mx-auto sm:mx-0 mt-2 mb-4 ">
                <Button label="Register" class="w-fit sm:w-full" @click="routeToRegistration" />
            </div>
        </div>
    </div>
</template>
