<script setup>
import { axiosClient } from "../services/axiosService";
import { computed, onMounted, ref, nextTick, watch } from "vue";
import { useForm } from "vee-validate";
import * as yup from "yup";

// Components
import mentornetLabel from "./mentornetLabel.vue";
import mentornetHint from "./mentornetHint.vue";

// Primevue
import { useToast } from "primevue/usetoast";
import Button from "primevue/button";
import Checkbox from "primevue/checkbox";
import InputText from "primevue/inputtext";
import Message from "primevue/message";
import Password from "primevue/password";

const props = defineProps({
  authServerUrl: String,
  returnUrl: String,
  username: String,
});

const emit = defineEmits(["login"]);
const toast = useToast();

// Validation
const { handleSubmit, errors, defineField } = useForm({
  validationSchema: yup.object({
    usernameOrEmailAddress: yup
      .string()
      .required(
        "Please enter your username or the email address you registered with"
      ),
    password: yup.string().required("Please enter your password"),
  }),
});

// Form values
const [usernameOrEmailAddress, usernameOrEmailAddressAttrs] = defineField(
  "usernameOrEmailAddress"
);
const [password, passwordAttrs] = defineField("password");
const [rememberMe, rememberMeAttrs] = defineField("rememberMe");
const usernameHasBeenEdited = ref(false);

// Data
const usernamePrePopulated = ref(false);

// Methods
const login = handleSubmit(
  (values) => {
    let form = {
      username: values.usernameOrEmailAddress,
      password: values.password,
      rememberMe: values.rememberMe ?? false,
    };
    emit("login", form);
  },
  (issues) => {
    showValidationFailedMessage();
    const firstError = Object.keys(issues.errors)[0];
    const elementToFocus = document.querySelector(`[name="${firstError}"]`);
    elementToFocus?.scrollIntoView({
      behavior: "smooth",
    });
    elementToFocus.focus();
  }
);

const showValidationFailedMessage = () => {
  toast.add({
    severity: "error",
    summary: "Validation Error",
    detail: "Validation failed. Please correct any errors and try again.",
    life: 4000,
  });
};

const submitOnEnterKey = () => {
  const submitButton = document.getElementById("btnSubmit");
  if (submitButton != null) {
    submitButton.click();
  }
};

const checkForMultipleAccounts = async () => {
  if (
    usernameOrEmailAddress.value.includes("@") &&
    usernameOrEmailAddress.value.includes(
      ".",
      usernameOrEmailAddress.value.indexOf("@")
    )
  ) {
    const url = `${props.authServerUrl}api/Account/EmailHasMultipleActiveAccounts?emailAddress=${usernameOrEmailAddress.value}`;
    const http = axiosClient();
    await http.get(url).then((response) => {
      if (response.data === true) {
        // Redirect to selection...
        const selectUrl = `${
          props.authServerUrl
        }Account/ClassicLoginUserSelection?emailAddress=${
          usernameOrEmailAddress.value
        }&returnUrl=${encodeURIComponent(props.returnUrl)}`;
        window.location.replace(selectUrl);
      }
    });
  }
};

// Computed
const forgotPasswordLink = computed(() => {
  const returnUrl = props.returnUrl;
  return `${
    props.authServerUrl
  }Account/ForgotPassword?ReturnUrl=${encodeURIComponent(returnUrl)}`;
});

const passwordInputProps = computed(() => {
  if (errors.value.password) {
    return {
      autocomplete: "current-password",
      "aria-required": "true",
      name: "password",
      "aria-describedby": "passwordHelp",
    };
  } else {
    return {
      autocomplete: "current-password",
      "aria-required": "true",
      name: "password",
    };
  }
});

// lifecycle hooks
onMounted(async () => {
  // Ensure all child elements have been rendered
  await nextTick(() => {
    if (props.username && props.username !== "") {
      usernamePrePopulated.value = true;
      usernameOrEmailAddress.value = props.username;
      usernameHasBeenEdited.value = false;
      var passwordInput = document.getElementById("Password");
      if (passwordInput != null) {
        passwordInput.focus();
      }
    }
  });
});

// watchers
watch(usernameOrEmailAddress, () => {
  if (!usernamePrePopulated.value || usernameHasBeenEdited.value) {
    checkForMultipleAccounts();
  }
  usernameHasBeenEdited.value = true;
});
</script>

<template>
  <div class="bg-background text-textOnBackground">
    <form ref="form" id="frmLogin" @submit.prevent="login">
      <mentornet-label for="username" text="Username or Email Address" />
      <span class="p-input-icon-left w-full">
        <i class="fa-solid fa-user text-textOnSecondary"></i>
        <InputText
          id="username"
          name="username"
          type="text"
          v-model="usernameOrEmailAddress"
          v-bind="usernameOrEmailAddressAttrs"
          aria-required="true"
          autocomplete="username"
          :aria-describedby="
            errors.usernameOrEmailAddress ? 'usernameHelp' : ''
          "
        />
      </span>
      <mentornet-hint
        v-if="errors.usernameOrEmailAddress"
        id="usernameHelp"
        :text="errors.usernameOrEmailAddress"
        error
      />
      <Message severity="info" v-if="usernamePrePopulated" class="mb-4">
        The email address you entered has been replaced by your chosen account
        username.
      </Message>
      <mentornet-label for="password" text="Password" />
      <span class="p-input-icon-left w-full">
        <i
          class="fa-sharp fa-regular fa-lock-keyhole text-textOnSecondary z-10"
        ></i>
        <Password
          inputId="password"
          v-model="password"
          v-bind="passwordAttrs"
          :feedback="false"
          :toggle-mask="true"
          @keyup.enter="submitOnEnterKey"
          :input-props="passwordInputProps"
        >
        </Password>
      </span>
      <mentornet-hint
        v-if="errors.password"
        id="passwordHelp"
        :text="errors.password"
        error
      />
      <div
        class="flex flex-col-reverse sm:flex-row sm:justify-between sm:items-center mt-4"
      >
        <div class="flex items-center">
          <Checkbox
            input-id="rememberMe"
            v-model="rememberMe"
            v-bind="rememberMeAttrs"
            :binary="true"
          />
          <label for="rememberMe" class="ml-3 text-sm">Remember me</label>
        </div>
        <a
          :href="forgotPasswordLink"
          class="underline text-primary font-light text-sm"
          >Forgot your password?</a
        >
      </div>
      <Button @click="login" type="submit" label="Log In" class="w-full mt-4" />
    </form>
  </div>
</template>
