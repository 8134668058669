<script setup>
import { ref, computed } from 'vue'
import { store } from '../store/store'

const props = defineProps({
    systemUrl: String,
    brandFolderName: String,
    systemName: String,
    theme: String
})

// Primevue
import Image from 'primevue/image'

const now = ref(Date.now())

const currentYear = computed(() => {
    const today = new Date(now.value)
    return today.getFullYear()
})

const lightLogo = computed(() => {
    return `/Content/Branding/${props.brandFolderName}/Images/FooterLogo.png`
})
const darkLogo = computed(() => {
    return `/Content/Branding/${props.brandFolderName}/Images/FooterLogo-dark.png`
})
</script>

<template>
    <div></div>
    <footer class="grid grid-cols-2">
        <div class="text-xs sm:text-sm font-medium text-center mt-1 text-textOnBackground">
            &#169; {{ currentYear }} sfG MentorNet Ltd
        </div>
        <div>
            <a :href="systemUrl" target="_blank">
                <Image v-if="store.currentTheme === 'light'" :src="lightLogo" :alt="`${systemName} Logo`" :pt="{ image: { class: 'h-fit sm:h-[80%] w-fit sm:w-[80%]' } }" />
                <Image v-else :src="darkLogo" :alt="`${systemName} Logo`" :pt="{ image: { class: 'h-fit sm:h-[80%] w-fit sm:w-[80%]' } }" />
            </a>
        </div>
    </footer>
</template>
